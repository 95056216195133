<div class="container">
	<div class="row">
		<div class="col-xs-12">
			<h1>
				{{{title}}}
			</h1>
			<p>
				{{{content}}}
			</p>
		</div>
	</div>
</div>