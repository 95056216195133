<div class="modal-dialog">
	<div class="modal-content">
		<div class="modal-header">
			<button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
			<h2 class="h4 modal-title">{{{translations.titleLogin}}}</h2>
		</div>
		<form class="x-login--form">
			<div class="modal-body">
				<div class="form-group{{#if error}} has-error{{/if}}">
					<label class="control-label" for="loginUsername">{{{translations.labelUsername}}}</label> <input value="" id="loginUsername" name="username" type="text" class="form-control" autofocus="true">
				</div>
				<div class="form-group{{#if error}} has-error{{/if}}">
					<label class="control-label" for="loginPassword">{{{translations.labelPassword}}}</label> <input value="" id="loginPassword" type="password" name="password" class="form-control">
				</div>
				{{#if error}}
					<div class="alert alert-{{error.level}} margin-bottom--none">
						{{{error.text}}}
					</div>
				{{/if}}
			</div>
			<div class="modal-footer">
				<div class="row">
					<div class="col-xs-12 col-sm-6">
						<a id="loginForgotten" href="{{contextPath}}/forgottenPassword" class="x-login--forgotten x-login--link btn btn-link btn-block">{{{translations.ctaForgottenCredentials}}}</a>
					</div>
					<div class="col-xs-12 hidden-md hidden-lg">
						<div class="row">
							<hr class="margin-top margin-bottom" />
						</div>
					</div>
					<div class="col-xs-12 col-sm-6">
						<button id="loginSubmit" type="submit" class="x-login--submit btn btn-primary btn-block">{{{translations.ctaLoginSubmit}}}</button>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<div class="well text-left margin-bottom--none clearfix">
					{{{translations.contentLoginDescription}}}
					<a id="loginCreate" href="{{contextPath}}/createAccount" class="x-login--link">
						<div class="btn btn-primary btn-block margin-top">{{{translations.ctaCreateAccount}}}</div>
					</a>
				</div>
			</div>
		</form>
	</div>
</div>
