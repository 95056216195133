<div class="container">
	<div class="row">
		<div class="col-xs-12">
			<h2>{{{JspPublicCodes_JSP_CreateAccount_TITLE}}}</h2>
			<div class="row">
				<div class="errors-global col-xs-12"></div>
			</div>
			<div class="row">
				<div class="col-xs-12">
					<hr />
					<div id="personalDetailsView"></div>
				</div>
			</div>
			<div class="row">
				<div class="col-xs-12">
					<hr />
					<div id="contactDetailsView"></div>
				</div>
			</div>
			<div class="row">
				<div class="col-xs-12">
					<hr />
					<div id="userPreferencesView"></div>
				</div>
			</div>
			<div class="form-group margin-top text-right">
				<button type="submit" id="submitButton" class="x-submit btn btn-primary" value="Save Settings" name="save">
					{{{JspPublicCodes_JSP_CreateAccount_SAVE_SETTINGS}}}
				</button>
			</div>
		</div>
	</div>
</div>
