<div id="contactDetails">
	<h2>{{{JspPublicCodes_JSP_CreateAccount_YourContactDetails}}}</h2>
	<div class="row">
		<div class="input-unit ic-addressLine1 col-xs-12 col-md-6 form-group">
			<label class="control-label" for="addressLine1">{{{JspPublicCodes_JSP_CreateAccount_AddressLine1}}} <span data-toggle="tooltip" title="{{{JspPublicCodes_JSP_CreateAccount_Required_Field}}}">*</span></label>
			<input type="text" id="addressLine1" name="addressLine1" class="form-control" value="{{{inputAddressLine1}}}" maxlength="250"/>
			<span id="addressLine1Errors" class="fieldError errors-addressLine1"></span>
		</div>
		<div class="input-unit ic-addressLine2 col-xs-12 col-md-6 form-group">
			<label class="control-label" for="addressLine2">{{{JspPublicCodes_JSP_CreateAccount_AddressLine2}}}</label>
			<input type="text" id="addressLine2" name="addressLine2" class="form-control" value="{{{inputAddressLine2}}}" maxlength="250"/>
			<span class="fieldError errors-addressLine2"></span>
		</div>
	</div>
	<div class="row">
		<div class="input-unit ic-city col-xs-12 col-md-6 form-group">
			<label class="control-label" for="city">{{{JspPublicCodes_JSP_CreateAccount_City}}} <span data-toggle="tooltip" title="{{{JspPublicCodes_JSP_CreateAccount_Required_Field}}}">*</span></label>
			<input type="text" id="city" name="city" class="form-control" value="{{{inputCity}}}" maxlength="250"/>
			<span id="cityErrors" class="fieldError errors-city"></span>
		</div>
		<div class="input-unit ic-countyOrState col-xs-12 col-md-6 form-group">
			<label class="control-label" for="county">{{{JspPublicCodes_JSP_CreateAccount_CountyOrState}}}</label>
			<input type="text" id="countyOrState" name="countyOrState" class="form-control" value="{{{inputCountyOrState}}}" maxlength="250"/>
			<span class="fieldError errors-countyOrState"></span>
		</div>
	</div>
	<div class="row">
		<div class="input-unit ic-postcode col-xs-12 col-md-6 form-group">
			<label class="control-label" for="postcode">{{{JspPublicCodes_JSP_CreateAccount_PostcodeOrZip}}} <span data-toggle="tooltip" title="{{{JspPublicCodes_JSP_CreateAccount_Required_Field}}}">*</span></label>
			<input type="text" id="postcode" name="postcode" class="form-control" value="{{inputPostcode}}" maxlength="20"/>
			<span id="postcodeErrors" class="fieldError errors-postcode"></span>
		</div>
		<div class="input-unit ic-country col-xs-12 col-md-6 form-group">
			<label class="control-label" for="country">{{{JspPublicCodes_JSP_CreateAccount_Country}}} <span data-toggle="tooltip" title="{{{JspPublicCodes_JSP_CreateAccount_Required_Field}}}">*</span></label>
			<select id="country" name="country" class="form-control">
				<option value="">{{{JspPublicCodes_JSP_LABEL_SELECT}}}</option>
				{{#each referenceCountries}}
					<option value="{{id}}">{{{name}}}</option>
				{{/each}}
			</select>
			<span class="fieldError errors-country"></span>
		</div>
	</div>
	<div class="row">
		<div class="input-unit ic-timeZone col-xs-12 form-group">
			<label class="control-label" for="timeZone">{{{JspPublicCodes_JSP_CreateAccount_TimeZone}}} <span data-toggle="tooltip" title="{{{JspPublicCodes_JSP_CreateAccount_Required_Field}}}">*</span></label>
			<select id="timeZone" name="timeZone" class="form-control">
				<option value="">{{{JspPublicCodes_JSP_LABEL_SELECT}}}</option>
				{{#each referenceTimeZones}}
					<option value="{{id}}">{{{name}}}</option>
				{{/each}}
			</select>
			<span id="timeZoneErrors" class="fieldError errors-timeZone"></span>
		</div>
	</div>
	<div class="row">
		<div class="input-unit ic-phoneNumber col-xs-12 col-md-4 form-group">
			<label class="control-label" for="phoneNumber">{{{JspPublicCodes_JSP_SHORT_TELEPHONE}}} <span data-toggle="tooltip" title="{{{JspPublicCodes_JSP_CreateAccount_Required_Field}}}">*</span></label>
			<input type="text" id="phoneNumber" name="phoneNumber" class="form-control" value="{{inputPhoneNumber}}" maxlength="30"/>
			<span class="fieldError errors-phoneNumber" id="phoneNumberErrors"></span>
		</div>
		<div class="input-unit ic-mobileNumber col-xs-12 col-md-4 form-group">
			<label class="control-label" for="mobileNumber">{{{JspPublicCodes_JSP_SHORT_MOBILE}}}</label>
			<input type="text" id="mobileNumber" name="mobileNumber" class="form-control" value="{{inputMobileNumber}}" maxlength="30"/>
			<span class="fieldError errors-mobileNumber" id="mobileNumberErrors"></span>
		</div>
		<div class="input-unit ic-faxNumber col-xs-12 col-md-4 form-group">
			<label class="control-label" for="faxNumber">{{{JspPublicCodes_JSP_SHORT_FAX}}}</label>
			<input type="text" id="faxNumber" name="faxNumber" class="form-control" value="{{inputFaxNumber}}" maxlength="30"/>
			<span class="fieldError errors-faxNumber"></span>
		</div>
	</div>
</div>