<div class="row">
  <div class="col-md-8 col-md-offset-2 ui-register-auction">
    <h2>{{{auctionTitle}}}</h2>
    <div>{{{registrationText}}}</div>
    <h2>{{{JspPublicCodes_JSP_AUCTION_REGISTRATION}}}</h2>
    {{#if auctioneerCentric}}
      <div>
        {{{JspPublicCodes-JSP_AUCTIONEER_STRIP_SALEMGTBY}}}
        <img src="{{#cloudify auctioneerLogo logoDims}}{{/cloudify}}">
        <h3>{{{auctioneerName}}}</h3>
      </div>
    {{/if}}
    {{#if spendingLimitRequired}}
      <div class="form-group {{hasError1}}">
        <label class="col-sm-4 control-label" for="offerAmount">{{{label1}}}</label>
        <div class="col-sm-6 input-group">
          <div class="input-group-addon">{{{currency.symbol}}} {{{currency.code}}}</div>
          <input type="text" class="x-add-commas form-control" id="spendingLimit" placeholder="" value="{{spendingLimit}}"
            maxlength="16" />
        </div>
      </div>
    {{/if}}
    <form class="form-horizontal">
      <div class="form-group {{hasError2}}">
        <div class=" col-sm-12">
          <div class="checkbox">
            <label><input class="x-agree-terms" type="checkbox" id="agreedTerms" value="collect" {{agreed}} />
              <a {{#if hasError2}}style="color:red;" {{/if}} id="auctionTerms"
                href="{{contextPath}}/content/auctionTerms/{{{auctionId}}}">{{{label2}}}</a>
            </label>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class=" col-sm-12">
          <button type="submit"
            class="x-submit btn btn-primary">{{{JspPublicCodes_JSP_AUCTION_REGISTRATION_REGISTER}}}</button>
        </div>
      </div>
    </form>
  </div>
</div>