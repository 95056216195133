<div class="container">
	<div class="row">
		<div class="col-xs-12 margin-bottom">
			<h1>{{{JspPublicCodes_JSP_CreateAccount_CreateAccount}}}</h1>
			<div>
				<div class="row">
					<div class="errors-global col-xs-12"></div>
				</div>
				<div class="row">
					<div class="col-xs-12">
						<hr />
						<div id="usernamePasswordView"></div>
					</div>
				</div>
				<div class="row">
					<div class="col-xs-12">
						<hr />
						<div id="personalDetailsView"></div>
					</div>
				</div>
				<div class="row">
					<div class="col-xs-12">
						<hr />
						<div id="contactDetailsView"></div>
					</div>
				</div>
				<div class="row">
					<div class="col-xs-12">
						<hr />
						<div id="userPreferencesView"></div>
					</div>
				</div>
				<div class="row">
					<div class="col-xs-12">
						<hr />
						<div id="termsAndConditionsView"></div>
					</div>
				</div>
				<div class="row">
					<div class="col-xs-12 text-right">
						<button type="submit" id="submitButton" class="x-submit btn btn-primary btn-lg">{{{JspPublicCodes_JSP_CreateAccount_CreateAccount}}}</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
