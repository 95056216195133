<div id="personalDetails">
	<h2>{{{JspPublicCodes_JSP_CreateAccount_PersonalDetails}}}</h2>
	<div class="row">
		<div class="input-unit ic-forename col-xs-12 col-md-6 form-group">
			<label class="control-label" for="forename">{{{JspPublicCodes_JSP_CreateAccount_Forename}}} <span data-toggle="tooltip" title="{{{JspPublicCodes_JSP_CreateAccount_Required_Field}}}">*</span></label>
			<input type="text" id="forename" name="forename" class="form-control" value="{{{inputForename}}}" maxlength="30"/>
			<span id="forenameErrors" class="fieldError errors-forename"></span>
		</div>
		<div class="input-unit ic-surname col-xs-12 col-md-6 form-group">
			<label class="control-label" for="surname">{{{JspPublicCodes_JSP_CreateAccount_Surname}}} <span data-toggle="tooltip" title="{{{JspPublicCodes_JSP_CreateAccount_Required_Field}}}">*</span></label>
			<input type="text" id="surname" name="surname" class="form-control" value="{{{inputSurname}}}" maxlength="30"/>
			<span id="surnameErrors" class="fieldError errors-surname"></span>
		</div>
	</div>
	<div class="row">
		<div class="input-unit ic-companyName col-xs-12 col-md-6 form-group">
			<label class="control-label" for="companyName">{{{JspPublicCodes_JSP_CreateAccount_CompanyName}}}</label>
			<input type="text" id="companyName" name="companyName" class="form-control" value="{{{inputCompanyName}}}" maxlength="150"/>
			<span class="fieldError errors-companyName"></span>
		</div>
		<div class="input-unit ic-jobTitle col-xs-12 col-md-6 form-group">
			<label class="control-label" for="jobTitle">{{{JspPublicCodes_JSP_CreateAccount_JobTitle}}}</label>
			<input type="text" id="jobTitle" name="jobTitle" class="form-control" value="{{{inputJobTitle}}}" maxlength="150"/>
			<span class="fieldError errors-jobTitle"></span>
		</div>
	</div>
	{{#if requestVatNumber}}
	<div class="row">
		<div class="input-unit ic-vatNumber col-xs-12 col-md-6 form-group">
			<label class="control-label" for="vatNumber">{{{JspPublicCodes_JSP_LABEL_VAT}}}</label>
			<input type="text" id="vatNumber" name="vatNumber" class="form-control" value="{{{inputVatNumber}}}" maxlength="20"/>
			<span class="fieldError errors-vatNumber"></span>
		</div>
	</div>
	{{/if}}
</div>
