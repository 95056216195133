<div id="usernameAndPasswordSection">
	<h2>{{{JspPublicCodes_JSP_CreateAccount_YourAccount}}}</h2>
	<div class="row">
		<div class="input-unit ic-username col-xs-12 col-md-6 form-group">
			<label class="control-label" for="username">{{{JspPublicCodes_JSP_CreateAccount_Username}}} <span data-toggle="tooltip" title="{{{JspPublicCodes_JSP_CreateAccount_Required_Field}}}">*</span></label>
			<input type="text" id="username" name="username" class="form-control" maxlength="100"/>
			<span class="fieldError errors-username" id="usernameErrors"></span>
		</div>
	</div>
	<div class="row">
		<div class="input-unit ic-email col-xs-12 col-md-6 form-group">
			<label class="control-label" for="email">{{{JspPublicCodes_JSP_CreateAccount_Email}}} <span data-toggle="tooltip" title="{{{JspPublicCodes_JSP_CreateAccount_Required_Field}}}">*</span></label>
			<input type="text" id="email" name="email" class="form-control" maxlength="120"/>
			<span class="fieldError errors-email" id="emailErrors"><form:errors name="email" /></span>
		</div>
		<div class="input-unit ic-repeatEmail col-xs-12 col-md-6 form-group">
			<label class="control-label" for="repeatEmail">{{{JspPublicCodes_JSP_CreateAccount_RepeatEmail}}} <span data-toggle="tooltip" title="{{{JspPublicCodes_JSP_CreateAccount_Required_Field}}}">*</span></label>
			<input type="text" id="repeatEmail" name="repeatEmail" class="form-control" maxlength="120"/>
			<span class="fieldError errors-repeatEmail" id="repeatEmailErrors"><form:errors path="repeatEmail" maxlength="250"/></span>
		</div>
	</div> 
	<div class="row">
		<div class="input-unit col-xs-12 col-md-6 form-group">
			<div class="ic-password">
				<label class="control-label" for="password">{{{JspPublicCodes_JSP_CreateAccount_Password}}} <span data-toggle="tooltip" title="{{{JspPublicCodes_JSP_CreateAccount_Required_Field}}}">*</span></label>
				<input id="password" name="password" value="{{password}}" type="password" class="x-password form-control" maxlength="100"/> 
				<span class="control-label errors-password" id="passwordErrors"></span>
			</div> 
			<div class="">
				<label class="control-label" for="passwordStrengthBar">{{{JspPublicCodes_JSP_CreateAccount_Strength}}}</label>
				<div class="progress">
					<div id="complexity-bar" class="x-progress progress-bar" role="progressbar" style="color: #f00; width: 0%;"></div>
				</div>
			</div>
		</div>
		<div class="input-unit ic-repeatPassword col-xs-12 col-md-6 form-group">
			<label class="control-label" for="repeatPassword">{{{JspPublicCodes_JSP_CreateAccount_RepeatPassword}}} <span data-toggle="tooltip" title="{{{JspPublicCodes_JSP_CreateAccount_Required_Field}}}">*</span></label> 
			<input value="{{repeatPassword}}" id="repeatPassword" name="repeatPassword" type="password" class="x-confirm form-control" maxlength="100"/> 
			<span class="control-label errors-repeatPassword" id="repeatPasswordErrors"></span>
		</div>
	</div> 
</div>