<h2>{{{JspPublicCodes_JSP_FOOTER_TS_AND_CS}}}</h2>
<div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
	<div class="panel panel-default">
		<div class="x-content panel-heading" role="tab" id="termsHeading" data-content="terms">
			<h4 class="panel-title">
				<a data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">{{{JspPublicCodes_JSP_FOOTER_TS_AND_CS}}}</a>
			</h4>
		</div>
		<div id="collapseOne" class="panel-collapse collapse" role="tabpanel" aria-labelledby="termsHeading">
			<div class="panel-body" id="terms-content-body" ></div>
		</div>
	</div>
	<div class="panel panel-default">
		<div class="x-content panel-heading" role="tab" id="privacyHeading" data-content="privacy">
			<h4 class="panel-title">
				<a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">{{{JspPublicCodes_JSP_FOOTER_PRIVACY_POLICY}}}</a>
			</h4>
		</div>
		<div id="collapseTwo" class="panel-collapse collapse" role="tabpanel" aria-labelledby="privacyHeading">
			<div class="panel-body" id="privacy-content-body"></div>
		</div>
	</div>
</div>

<div class="row">
	<div class="form-group col-xs-12">
		<div class="input-unit error-aha-agreedTsAndCsAndPrivacyPolicy ic-agreedTsAndCsAndPrivacyPolicy">
			<label for="agreedTsAndCsAndPrivacyPolicy"> 
				<input id="agreedTsAndCsAndPrivacyPolicy" name="agreedTsAndCsAndPrivacyPolicy" type="checkbox" />&nbsp;{{{JspPublicCodes_JSP_CreateAccount_AgreeTerms}}}
			</label>
			<span class="fieldError errors-agreedTsAndCsAndPrivacyPolicy" id="agreedTsAndCsAndPrivacyPolicyErrors"></span>
		</div>
	</div>
</div>
