<h2>{{{JspPublicCodes_JSP_CreateAccount_YourPrefs}}}</h2>
<div class="form-group">
    <div class="">
        <label for="dataAnalyticsOk"> <input id="dataAnalyticsOk" name="dataAnalyticsOk" type="checkbox" />&nbsp;{{{JspPublicCodes_JSP_DATA_ANALYTICS_OPT_IN}}}
        </label>
    </div>
</div>
<div class="error-aha-receiveEmailsOk">
	<div class="form-group">
		<div class="input-unit ic-receiveEmailsOk">
			<label for="receiveEmailsOk"> <input id="receiveEmailsOk" name="receiveEmailsOk" type="checkbox" />&nbsp;{{{JspPublicCodes_JSP_EMAIL_OPT_IN}}}
			</label>
			<span class="control-label fieldError errors-receiveEmailsOk" id="receiveEmailsOkErrors"></span>
		</div>
	</div>
</div>
