<div class="container">
	<div class="row">
		<div class="col-xs-12">
			{{#if user.enabled}}
			<p>
					{{{content}}}
			</p>
			{{else}}
				<h2>{{{JspPublicCodes_JSP_ACCOUNT_NOT_RECOGNISED}}}</h2>
				<p>{{{JspPublicCodes_JSP_ACCOUNT_NOT_RECOGNISED_MESSAGE}}}</p>
			{{/if}}
		</div>
	</div>
</div>