<div class="row">
  <div class="col-md-8 col-md-offset-2">
    <h2>{{{content}}}</h2>
    {{#if showDeposit}}
      <div>
        <a id="payDepositButton" class="button button-primary"
          href="{{contextPath}}/payDeposit?registrantId={{{registrationId}}}"><span>{{{JspPublicCodes_JSP_BIDDING_PAY_DEPOSIT}}}</span></a>
      </div>
    {{/if}}
  </div>
</div>
